const query = `
  mutation createFundraiser(
    $title: String,
    $fName: String,
    $lName: String,
    $email: String,
    $encryptionKey: String,
    $vanId: String,
    $goal: Number,
    $expDate: DateTime,
    $coverPhoto: [Int],
    $description: String,
    $slug:String,
    $authorId: ID,
  ) {
    save_fundraiser_fundraiser_Entry(
      title: $title,
      fName: $fName,
      lName: $lName,
      heading2: $email,
      heading0: $description,
      heading4: $encryptionKey,
      heading5: $vanId,
      expDate: $expDate,
      goal: $goal,
      backgroundImage0: $coverPhoto,
      authorId: $authorId,
      slug: $slug,
      siteId: 9
    ) {
      title
      slug
      backgroundImage0 {
        id
      }
      fName
      lName
      heading2
      heading0
      heading4
      heading5
      expDate
      goal
    }
  }
`.replace(/(\n|\s)/g, " ");

const createAction = async formData => {
  try {
    const variables = {
      ...formData,
      authorId: 43274,
      coverPhoto: [parseInt(formData.coverPhoto, 10)],
    };
    const entry = await fetch("/api/mutate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(res => res.json())
      .then(res => res)
      .catch(e => {
        throw Error(e);
      });
    return entry;
  } catch (e) {
    throw Error(e);
  }
};

export default createAction;
