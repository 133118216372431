const query = `
  mutation createAction(
    $id: ID,
    $title: String,
    $fName: String,
    $lName: String,
    $goal: Number,
    $expDate: DateTime,
    $coverPhoto: [Int],
    $description: String,
  ) {
    save_fundraiser_fundraiser_Entry(
      id: $id,
      title: $title,
      fName: $fName,
      lName: $lName,
      heading0: $description,
      expDate: $expDate,
      goal: $goal,
      backgroundImage0: $coverPhoto,
      siteId: 9
    ) {
      id
      title
      backgroundImage0 {
        id
      }
      fName
      lName
      heading0
      expDate
      goal
    }
  }
`.replace(/(\n|\s)/g, " ");

const createAction = async formData => {
  try {
    const variables = {
      ...formData,
      authorId: 43274,
      coverPhoto: [parseInt(formData.coverPhoto, 10)],
    };
    const entry = await fetch("/api/editfundraiser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
        key: formData.key,
      }),
    })
      .then(res => res.json())
      .then(res => res)
      .catch(e => {
        throw Error(e);
      });
    return entry;
  } catch (e) {
    throw Error(e);
  }
};

export default createAction;
