import resolveImage from "@dataResolvers/resolveImage";
import resolveCB from "@dataResolvers/resolveCB";
import resolveSidebar from "@dataResolvers/resolveSidebar";

const resolvePage = entry => {
  const {
    slug,
    title,
    url,
    metaTitle,
    metaDescription,
    metaImage,
    copy0,
    heading0,
    backgroundImage0,
    contentBuilder,
    // sidebar
    hideSidebar,
    hideDefaultActions,
    sidebarActions,
    hideDefaultLinks,
    sidebarLinks,
    twitterTitle,
    twitterDescription,
    twitterImage,
  } = entry;

  return {
    url,
    slug,
    meta: {
      twitterTitle,
      twitterDescription,
      twitterImage: resolveImage(twitterImage),
      title,
      metaDescription,
      metaImage: resolveImage(metaImage),
      metaTitle,
      slug,
      url,
    },
    hero: {
      heading: heading0,
      copy: copy0,
      backgroundImage: resolveImage(backgroundImage0),
    },
    contentBuilder: resolveCB(contentBuilder),
    sidebar: resolveSidebar({
      hideSidebar,
      hideDefaultActions,
      sidebarActions,
      hideDefaultLinks,
      sidebarLinks,
    }),
  };
};

export default resolvePage;
